<template>
    <v-app>
        <div class="form-signin-wrap">
            <div class="form-signin">
                <div class="container">
                    <div class="auth-img-wrap">
                      <v-img contain :src="STATIC.appLogoUrl" class="auth-img" alt=""></v-img>
                    </div> 
                    <div class="form-group text-center pb-2">
                        <h3 class="font-weight-light">Личный кабинет</h3>
                        <h6 class="font-weight-light">{{STATIC.appLoginTitle}}</h6>
                    </div>
                    <div class="form-group">
                        <v-form ref="form" v-model="valid">
                            <v-text-field
                                v-model="loginForm.login"
                                :counter="18"
                                :rules="loginRules"
                                rounded
                                type="tel"
                                maxlength="20"
                                required
                                outlined
                                dense
                                id="login_input"
                                v-mask="'+7 (###) ###-##-##'"
                                placeholder="Телефон"
                            >
                              
                            </v-text-field>
                            <v-text-field
                                v-model="loginForm.password"
                                :rules="passwordRules"
                                placeholder="Пароль"
                                required
                                rounded
                                outlined
                                id="password_input"
                                dense
                                autocomplete="off"
                                v-on:keyup.enter="login"
                                :append-icon="showEye ? (showPassword ? 'mdi-eye' : 'mdi-eye-off') : ''"
                                @click:append="showPassword = !showPassword"
                                :type="showPassword ? 'text' : 'password'"
                            ></v-text-field>
                            <div class="d-flex align-center justify-space-between">
                                <v-row>
                                    <v-col cols=12 class="pt-5">
                                        <v-btn 
                                            :loading="loading" 
                                            :dark="valid" 
                                            :disabled="!valid" 
                                            @click="validate" 
                                            rounded
                                            color="primary"
                                            block
                                        >
                                        Войти
                                        </v-btn>
                                    </v-col>
                                    <v-col sm="12" md="6">
                                         <v-btn 
                                            small 
                                            rounded
                                            block
                                            @click="toRegisterPage"
                                            link
                                        >
                                        Регистрация
                                        </v-btn>
                                    </v-col>
                                    <v-col sm="12" md="6">
                                        <v-btn 
                                            small 
                                            rounded
                                            block
                                            @click="toRecoveryPage(loginForm.login)"
                                            link
                                        >
                                        Забыли пароль?
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mt-3">
                                <div v-if="authError" class="error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message text-center">Неверный телефон или пароль</div>
                                    </div>
                                </div>
                            </div>
                        </v-form>
                    </div>
                    <div class="form-group text-center text-muted">
                        <small>Нажимая кнопку «Войти», Вы принимаете условия <a target="_blank" :href="STATIC.appAgreementUrl">Пользовательского соглашения</a> и <a target="_blank" :href="STATIC.appPrivacyUrl">Политики конфиденциальности</a></small>
                    </div>
                    <div class="form-group text-center text-muted">
                        <small>{{year}} © Киберсофт.Здоровье</small>
                        <div class="version-label"><i class="fas fa-code-branch"></i> v.2.1.8.28
                          <span id="cordova_ready"></span> <router-link to="/appointments">Version</router-link>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
        <v-dialog v-model="showHaveAccountModal" max-width="400">
          <v-card class="pa-2">
            <v-card-title class="w-100">
              <span class="text-center w-100 grey--text text--darken-4">
                У вас уже есть аккаунт?
              </span>
            </v-card-title>
            <v-card-text>
              <v-img max-height="250px" class="my-6" contain src="@/assets/pic.png"></v-img>
              <v-btn height="45" block depressed color="primary" class="mb-4 mt-4" @click="showHaveAccountModal = false">Войти</v-btn>
              <v-btn height="45" block depressed color="primary" outlined @click="toRegisterPage()" class="register-modal-btn">Зарегистрироваться</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
    </v-app>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Login',
  data: function() {
    return {
      loginForm: {
        login: '',
        password: '',
      },
      siteKey: window.config.reCaptcha.siteKey,
      showEye: false,
      showPassword: false,
      loginRules: [v => !!v || 'Введите телефон', v => (v && v.length <= 18) || 'Телефон должен быть меньше 10 цифр'],
      passwordRules: [v => !!v || 'Введите пароль'],
      valid: false,
      authError: false,
      loading: false,
      year: new Date().getFullYear(),
      showHaveAccountModal: false
    }
  },
  mounted() {
      window.location.href = 'https://stud.stavmk.ru';
    //const span = this.$refs['label-span'];
    //span.parentElement.classList.add('v-label--active');
    if (localStorage.getItem('token')) {
      this.$router.push('/')
    }

    this.loginForm.login = '';  
    // Если мобильное устройство
    if (window.cordova){
      // Если установлены логи и пароль и мобильное устройство
      if (window.device && localStorage.getItem('login') && localStorage.getItem('password')){
        if (window.device.platform === 'iOS' || window.device.platform === 'Android') {
          // Пробуем авторизоватся
          this.loading = true;
          let authParams = {
            login: localStorage.getItem('login'),
            password: localStorage.getItem('password'),
            pushToken: window.pushToken,
            platform: window.indexPlatform
          }
          this.login(authParams)
          .then(() => {
            this.loadStatic();
            if(localStorage.getItem('primaryPath') === '/login') {
              localStorage.removeItem('primaryPath');
            }
            this.$router.push(localStorage.getItem('primaryPath') || '/');
            localStorage.removeItem('primaryPath');
          }).catch(err => {
            this.loading = false;
            localStorage.removeItem('login');
            localStorage.removeItem('password');
            console.log(err)
          })
        }
      }
    }
    
    const url = new URL(window.location.href);
    const refer = url.searchParams.get('refer');
    if (refer === 'site') {
      url.searchParams.delete('refer');
      window.history.replaceState({}, document.title, url);
      this.showHaveAccountModal = true;
    }
  },
  watch: {
    "loginForm.password": function (val) {
        if (val.length <= 1){
            this.showEye = true;
         }
    },
  },
  methods: {
    validate () {
      this.authError = false;
      this.$refs.form.validate()
      // Проверяем валидацию
      if (this.valid) {
        let authParams = {
          login: this.loginForm.login,
          password: this.loginForm.password,
          pushToken: window.pushToken,
          platform: window.indexPlatform
        };
        this.loading = true;
        this.login(authParams)
          .then((response) => {
            this.loadStatic();
            this.loading = false;
            // this.getAdvertising().then((r) => {
            //   if(r.data.advertising) {
            //     localStorage.setItem('dateTimesAdvertising', (new Date()).getTime());
            //   }
            // });
            if(localStorage.getItem('primaryPath') === '/login') {
              localStorage.removeItem('primaryPath');
            }
            this.$router.push(localStorage.getItem('primaryPath') || '/');
            localStorage.removeItem('primaryPath');
          })
          .catch(err => {
            console.log(err)
            this.authError = true
            this.loading = false
          })
      }
    },
    ...mapActions([
      'login',
      'loadStatic',
      'getAdvertising',
    ]),
    toRegisterPage() {
        this.$router.push('/register') 
    },
    toRecoveryPage(phone = null) {
        let searchParams = '';
        if (phone) {
          searchParams = `?phone=${phone}`
        }
        this.$router.push(`/recovery${searchParams}`);
    },
  },
  computed: {
    ...mapGetters([
      'STATIC'
    ])
  },
   created() {
       window.location.href = 'https://stud.stavmk.ru';
   }
}
</script>

<style>

.grecaptcha-badge { 
  visibility: hidden;
}

.google-terms{
  font-size: 10px;
  text-align: center;
  color: #b6b6b6;
}

.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}

.form-signin-wrap{
    display: flex;
    height: 100%;
}

.auth-img-wrap{
    display: flex;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-img{
    margin: auto;
    width: 100%;
    height: auto;
    max-height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
    pointer-events: none;
}

.version-label{
  font-size: 9px;
  color: #ccc;
}

.register-modal-btn:hover {
  text-decoration: none;
}

</style>
