<template>
  <div>
    <div class="header-mobile">
      <v-container class="mb-5">
        <div>
          <v-row no-gutters>
            <v-col cols="12">

              <div class="my-2" v-if="loading">
                <v-skeleton-loader type="table-heading, list-item-two-line, image, table-tfoot" class="loader"></v-skeleton-loader>
                <v-skeleton-loader type="table-heading, list-item-two-line, image, table-tfoot" class="loader"></v-skeleton-loader>
              </div>

              <div v-if="!loading && this.couponInfo">
                <v-card class="py-4 mx-auto" max-width="400px">
                  <div class="text-center">
                    <div>Запись</div>
                    <div>
                      <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>
                      <small>{{ dateFormat(this.couponInfo.date, this.couponInfo.isAdditional) }}</small>
                    </div>
                    <div v-bind:class="{'d-none': !this.couponInfo.customer}">
                      <small><b>Пациент:</b> {{ (this.couponInfo.customer) ? this.couponInfo.customer.trim() : '' }}</small>
                    </div>
                    <div v-bind:class="{'d-none': !this.couponInfo.user}">
                      <small><b>Специалист:</b> {{ (this.couponInfo.user) ? this.couponInfo.user.trim() : '' }}</small>
                    </div>
                    <div v-bind:class="{'d-none': !this.couponInfo.service}">
                      <small><b>Услуга:</b> {{ (this.couponInfo.service) ? this.couponInfo.service.trim() : '' }}</small>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="!loading && template">
                <questionTitle 
                  :description="template.description"
                  :name="template.nameReview"
                />
                <div v-for="(item, index) in template.templateItems" :key="item.id">
                  <ReviewItem
                    :index="index"
                    :type="item.itemType"
                    :title="item.title"
                    :ratingCount="item.ratingCount"
                    :options="item.variants"
                  />
                </div>
              </div>

              <div class="text-center" v-if="template && !sendCompleted">
                <v-btn @click="sendReviewValues" color="primary" text>
                  Отправить отзыв
                </v-btn>
              </div>

            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <v-dialog persistent v-model="errorDialog" max-width="400">
      <v-card>
        <v-card-title>Отзыв</v-card-title>
        <v-card-text>
          <p>{{errorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="closeWindow">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="sendCompleted" max-width="400">
      <v-card>
        <v-card-title>Отзыв</v-card-title>
        <v-card-text>
          <p>Отзыв отправлен. Спасибо за Ваше участие.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="closeWindow">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ReviewItem from '@/components/review/ReviewItem';
import questionTitle from '@/components/review/reviewItems/questionTitle.vue';
import router from '@/router';

export default {
  name: 'Review',
  data: function(){
    return {
      idReview: null, // ID отзыва
      token: null, // Токен
      sendCompleted: false, // Отзыв отправлен
      errorText: false, // Ошибки
      loading: false, // Состояние загрузки
      template: null,
      errorDialog: false
    };
  },
  mounted(){
    this.loading = true; // Включить анимацию загрузки
    // Получить информацию по отзыву
    this.getTemplateData()
  },
  components: {
    questionTitle,
    ReviewItem
  },
  methods:{
    // Форматирует дату
    dateFormat(date, isAdditional = false) {
      if (date) {
        if (isAdditional) {
          return this.$moment(date).format("LL, dddd");
        }
        return this.$moment(date).format("LL, dddd, LT");
      } else {
        return '';
      }
    },
    // Получить информацию по отзыву
    async getTemplateData(){
      this.id = this.$route.query.idReview; // ID отзыва
      this.token = this.$route.query.token; // Токен
      console.log(this.id, this.token);
      // Запрос на API
      this.$http.get(window.config.apiUrl + `/reviews/get-review-template?id=${this.id}&token=${this.token}`,{
        params: {
          id: this.id,
          token: this.token
        }
      })
      .then(response => {
        this.loading = false; // Отключить анимацию загрузки
        // Если ошибка запроса
        if (response.data.status === 'error'){
          this.errorDialog = true;
          this.errorText = response.data.errorText;
        } else {
           this.$store.commit('changeTemplateValues', response.data.review.reviewTemplate.templateItems)
           this.template = response.data.review.reviewTemplate;
           this.couponInfo = response.data.couponInfo;
        }
      })
    },
    closeWindow(){
        window.location.href = 'https://stud.stavmk.ru';
      // let queryParams = new URLSearchParams(window.location.search);
      // console.log(queryParams.get('from'));
      // if (queryParams.get('from') == 'appointments') {
      //   router.push('/appointments');
      //   return;
      // }
      // if (queryParams.get('from') == 'history') {
      //   router.push('/history');
      //   return;
      // }
      // window.location.href = 'https://stud.stavmk.ru';
    },
    // Отправить отзыв
    async sendReviewValues(){
      this.$http.post(window.config.apiUrl + '/reviews/save-user-review',{
        idReview: this.id, // ID отзыва
        token: this.token, // Токен
        values: this.templateValues,
      }).then(response => {
        if (response.data.status == 'error'){
          this.errorText = response.data.errorText;
        } else {
          this.sendCompleted = true;
        }
      }).catch(err => {
   
      })
    },
  },
  computed: mapGetters([
    'templateItems', 
    'templateValues',
    'STATIC'
  ])
};
</script>

<style scoped>


</style>
